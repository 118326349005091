.contact-container {
  padding: 6.75rem 5%;
}

.contact-container > .contact-header {
  padding-bottom: 1.938rem;
}

.contact-container > .contact-txt {
  padding-bottom: 2.188rem;
}

.contact-btn {
  background: #f7941e;
  border: none;
  width: 15rem;
  height: 3rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  margin-top: 35%;
}

.contact-reason {
  height: 17.875rem;
}

.contact-reason > .mdc-text-field {
  height: 17.875rem !important;
}
.contact-reason > .mdc-text-field > .mdc-text-field__input {
  height: 17.875rem !important;
}
.contact-reason > .mdc-text-field > .mdc-floating-label {
  top: 10% !important;
}
.contact-popUp {
  width: 36.375rem !important;
  top: 50% !important;
  transform: translate(0, -50%);
}
.ok-btn {
  background: #f7941e;
  border: none;
  width: 7.375rem;
  height: 3rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
}
