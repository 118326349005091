.loginPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.leftLogin {
  height: 100vh;
}

.rightLogin {
  width: 66%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .auth-provider {
    width: 100%;
    justify-content: center;
    align-items: center;
    .provider img,
    div:nth-child(2) img {
      width: 100%;
      height: 100%;
    }
    div:nth-child(2),
    .provider {
      width: 11.25rem !important;
      //height: 9.25rem !important;
    }
    div:nth-child(2) {
      margin: 0 4px !important;
    }
  }
}

.loginImg {
  height: 100vh;
  width: auto !important;
}

.login-welcome {
  margin-bottom: 2rem;
  width: 20.125rem !important;
  cursor: pointer;
}
.welcome {
  margin-bottom: 2.563rem;
}

.userLogin-btn {
  margin-bottom: 0.875rem;
}

.mdc-floating-label {
  top: 1rem !important;
  transform: translate(0%, -50%);
}
.mdc-text-field__input {
  font-size: 1.25rem !important;
}
.showHidePassword {
  width: 2rem;
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0px, -50%);
}
