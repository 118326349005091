.gbl-dialog{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  .dialog-container{
    background-color: #FFF;
    padding: 32px;
    border-radius: 24px;
    text-align: left;
    .btn-row{
      align-items: center;
      justify-content: flex-end;
      margin-top: 98px;
      .btn{
        border: none;
        min-width: 118px;
        height: 40px;
        border-radius: 26px;
        background-color: #F7941E;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        margin-left: 16px;
        span{
          color: #FFF;
        }
      }
    }
  }
}
