footer {
  padding: 7.5rem 6rem;
  box-sizing: border-box;
}
.footer-sections1,
.footer-sections2 {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.25rem;
}
.footer-links {
  color: #ffffff !important;
  text-decoration: none;
  padding: 0 1.563rem 0.938rem 0;
}
.footerLink-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.partners {
  transform: translate(0px, -3.125rem);
}
.partner-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .footer-sections1 {
    flex-direction: column;
    //gap: 8vh;
  }
  .footer-sections1 > * + * {
    margin-top: 8vh;
  }
  .footer-sections2 {
    flex-direction: column-reverse;
    gap: 5vh;
    padding-top: 7rem;
  }
  .partners {
    transform: none;
  }
  .footer-links {
    padding-bottom: 5rem;
  }
  .footer-row {
    gap: 5rem;
  }
}
@media screen and (orientation: portrait) {
  .footer-sections1 {
    flex-direction: column;
    //gap: 8vh;
  }
  .footer-sections1 > * + * {
    margin-top: 8vh;
  }
  .footer-sections2 {
    flex-direction: column-reverse;
    gap: 5vh;
    padding-top: 7rem;
  }
  .partners {
    transform: none;
  }
  .footer-links {
    padding-bottom: 5rem;
  }
  .footer-row {
    gap: 5rem;
  }
}
