.useLandscape-bg {
  z-index: 95;
  width: 100%;
  height: 100vh;
  top: 0;
  position: absolute;
  background-color: #fff;
  overflow-y: hidden;
}
.landscape-btn {
  color: #fff;
  background: #f7941e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: none;
  width: max-content;
  height: 45px;
  margin-top: 2.5rem;
  cursor: pointer;
  padding: 0 10%;
}
.popUp-img {
  width: 33.25rem;
  margin: auto;
}
.popUp-Mobileimg {
  width: 18.75rem;
  margin: auto;
}

.popupMsg {
  min-width: 36.375rem;
  border-radius: 24px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2.375em;
  position: relative;
  margin: 0 auto;
  top: 25%;
  max-width: 90%;
  background-color: white;
  z-index: 99;
}
@media screen and (orientation: portrait) {
  .useLandscape-bg {
    display: block !important;
  }
}
@media screen and (orientation: landscape) and (max-height: 600px) {
  .popupMsg {
    top: 5%;
  }
}
