.header-hr {
  height: 5px;
  background-color: #f7941e;
  color: #f7941e;
  border: none;
  margin: unset;
  width: 100%;
}
.dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
}
.db-menu {
  width: 13.625rem;
  min-height: 100%;
  border-right: #f0f0f0 4px solid;
}

.db-menu > button {
  padding: 2.375rem 1.75rem;
  background: none;
  border: none;
  overflow: hidden auto;
}
.program-menu-hide {
  // transition: all 1s ease-out;
  opacity: 0;
  width: 0;
  overflow: hidden;
}
.program-menu-active {
  //  transition: all 1s ease-out;
  opacity: 1;
  width: 26.75rem;
  height: 100%;
}
.program-menu {
  width: 26.75rem;
  min-height: 100%;
}
.program-menu-header {
  padding: 3.125rem;
}
.programs-titles {
  padding: 1.25rem 0px 1.25rem 3.125rem;
  background: none;
  border: none;
}
.programs-titles.selected {
  background: #f7941e;
  color: #fff;
  width: 100%;
}
.program-menu-hr {
  height: 1px;
  color: #a3a3a3;
  border: none;
  margin: unset;
  background-color: #a3a3a3;
}
.program-menu-hr-selected {
  height: 3px;
  color: #f7941e;
  background: #f7941e;
  border: none;
  margin: unset;
}
.program-display {
  padding: 7.938rem 23.75rem 3.125rem 3.125rem;
  text-align: left;
  position: relative;
  width: 100%;  
  height:90vh;
  overflow-y: scroll;
}
.program-display-hide {
  // transition: all 1s ease-out;
  opacity: 0;
  width: 0;
  overflow: hidden;
}
.program-display-active {
  // transition: all 1s ease-out;
  opacity: 1;
}
.program-input {
  width: 51.375rem;
  margin-bottom: 1.625rem;
  border: none;
  border-radius: unset !important;
}
.program-input-enabled {
  color: #222222 !important;
}
.program-input-disabled {
  color: #a3a3a3;
}
.program-desc {
  height: 7.5rem;
}
.program-bundle {
  width: 35.063rem;
}
.publish-btn-program {
  right: 30% !important;
}
.publish-btn {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  width: 12.5rem;
  height: 48px;
  position: absolute;
  top: 5rem;
  right: 5.625rem !important;
  border: none;
  background: #a3a3a3;
}
.publish-btn:enabled {
  background: #f7941e;
}
.publish-btn :disabled,
.publish-btn [disabled] {
  background-color: #a3a3a3;
}
.disable-prgm-btn {
  background: none;
  border: none;
  padding-left: 16px;
  white-space: nowrap;
}
.bundle-pad {
  padding: 1rem 0;
}
.pop-up-container {
  width: 100%;
  height: 100%;
  // opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.pop-ups {
  position: absolute;
  padding: 2.375rem;
  top: 20%;
  width: 36.375rem;
  background: #fff;
  border-radius: 24px;
  color: #333333;
  left: 50%;
  transform: translate(-75%, 0);
}
.pop-ups-btns {
  color: #fff;
  background: #f7941e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  border: none;
  //min-width: 14rem;
  min-width: 118px;
  height: 40px;
  // min-height: 3rem;
  margin: 0.625rem;
  cursor: pointer;
}
.pop-ups-btns:disabled {
  background: #a3a3a3 !important;
}
.del-projection-btn {
  color: #fff;
  background: #f7941e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  border: none;
  min-width: 14rem;
  min-height: 2rem;
  margin: 0.625rem;
  cursor: pointer;
}
.analytics-btn {
  color: #fff;
  background: #f7941e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  border: none;
  width: 12.5rem;
  height: 3rem;
  margin-left: 2.5rem;
}
.userContact-display {
  position: relative;
  padding: 7.938rem 0 3.125rem 3.125rem;  
  height: 90vh;
  overflow-y: scroll;
}
.userContact-hide {
  // transition: all 1s ease-out;
  opacity: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
}
.userContact-active {
  //  transition: all 1s ease-out;
  opacity: 1;
  width: 100.375rem;
  // height: 200vh;
}
.userContact-table {
  width: 99%;
  border-spacing: 0;
  border-collapse: collapse;
  margin: auto;
}
.userContact-table-th {
  padding-bottom: 1.25rem;
}
.userContact-td {
  border-bottom: 1px solid #535353;
  text-align: left;
  img {
    width: 3.25rem !important;
  }
}
.userName {
  padding: 0 24px;
}
.table-row-padding {
  padding: 24px 0;
}
.gbl-admin {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  align-items: flex-start;
  * {
    box-sizing: border-box;
  }
  .container {
    width: 100%;
    // height: 100%;
    height: 175vh;
    overflow: hidden;
    align-items: flex-start;
  }
}
.page-content {
  flex: 1;
  height: 100%;
  align-items: flex-start;
  overflow: hidden;
  .page-input {
    resize: none;
  }
  .page-content-container {
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .page-input-container {
    height: 100%;
    overflow: hidden;
  }
  .program-menu {
    overflow: hidden auto;
    height: 100%;
  }
  .gbl-dialog {
    .heading {
      margin-bottom: 16px;
    }
  }
  .program-display {
    width: 100%;
    padding: 7.938rem 0 3.125rem 3.125rem;
  }
  .inputs {
    height: 100%;
    width: 100%;
    padding-right: 23.75rem;
    overflow: hidden auto;
  }

  .btn-add-faq {
    background-color: transparent;
    border: none;
    margin-left: 16px;
    span {
      color: #f7941e;
    }
  }
  .faq-card {
    background: #e6e5d6;
    padding: 24px;
    position: relative;
    margin-top: 32px;
    .btn-close {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
      height: 24px;
      img {
        width: 22px;
        height: 21px;
        object-fit: contain;
      }
    }
    .faq-head {
      color: #222222;
      margin-top: 8px;
      width: 100%;
    }
    .faq-input {
      background-color: #f0f0f0;
      resize: none;
      margin-top: 24px;
      padding: 2% 3%;
      width: 100%;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .faq-list {
    padding-bottom: 32px;
  }
}
