.header-content {
  justify-content: space-between;
  padding: 2rem 5%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.link-container {
  width: 40vw;
  padding-right: 64px;
}
.header-links {
  padding-right: 5%;
  margin-right: 3%;
  text-decoration: none;
}
.header-logos {
  width: 23%;
}
.mobile-hide {
  display: block;
}
.hamburger {
  display: none;
}
.button-icon {
  border: none;
  background-color: transparent;
}
.profilePic {
  max-width: 4rem;
  max-height: 4rem;
  object-fit: contain;
}
.profile-btn {
  border: none;
  background: transparent;
  padding-left: 1rem;
}
.userOptions {
  // min-width: 24.5rem;
  // max-width: 24.5rem;
  width: 24.5rem;
  height: 18rem;
  position: absolute;
  right: 0;
  top: 5.625rem;
  padding: 1.313rem 0;
  z-index: 1000;
}
.profile-dropDown {
  padding: 0 2.313rem;
  width: 100%;
  min-height: 3.6rem;
  background: transparent;
  border: none;
  margin-bottom: 1.25rem;
}
.profile-dropDown:hover {
  background: #f7941e !important;
}
.dropdown-toggle {
  transform: rotate(180deg);
}
.dropdown-toggle::before {
  transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
  .mobile-hide {
    display: none !important;
  }
  .hamburger {
    display: block;
  }
  .header-logos {
    width: 60%;
  }
  .mobile-links {
    padding: 3.5rem;
    text-decoration: none;
  }
  .float-menu-container {
    height: 99vh;
  }
  .float-menu {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 90vh;
    z-index: 100;
  }
  .close-btn {
    padding-left: 80%;
    padding-top: 5%;
  }
}

@media screen and (orientation: portrait) {
  .hamburger {
    display: block;
  }
  .mobile-hide {
    display: none !important;
  }
  .float-menu-container {
    height: 99vh;
  }
  .float-menu {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 90vh;
    justify-content: space-around;
    z-index: 100;
  }
}
