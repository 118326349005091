body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Large desktop screens
html {
  font-size: 16px;
  overflow-y: scroll;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
* {
  color: #222222;
}

button {
  cursor: pointer;
}

// Tablets and mobile screens
@media screen and (max-width: 1050px) {
  html {
    font-size: 8px !important;
  }
  .h5,
  .h6,
  .h7 {
    font-size: 14px !important;
  }
  .h10,
  .h11 {
    font-size: 15px !important;
  }
  .h13,
  .h12 {
    font-size: 12px !important;
  }
}

// Medium desktop screens
@media screen and (max-width: 1500px) {
  html {
    font-size: 12px;
  }
}

img {
  //Please don't comment this. It messes up all static pages on mobile.
  width: 100% !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.h1 {
  font-family: "Inria Sans";
  font-size: 4.375rem;
  font-weight: 400;
}

.h2 {
  font-family: "Inria Sans";
  font-size: 3.25rem;
  font-weight: 400;
}

.h3 {
  font-family: "Inria Sans";
  font-size: 3.25rem;
  font-weight: 300;
}

.h4 {
  font-family: "Inria Sans";
  font-size: 2.25rem;
  font-weight: 700;
}

.h5 {
  font-family: "Inria Sans";
  font-size: 1.5rem;
  font-weight: 700;
}

.h6 {
  font-family: "Inria Sans";
  font-size: 1.25rem;
  font-weight: 700;
}

.h7 {
  font-family: "Inria Sans";
  font-size: 1.25rem;
  font-weight: 300;
}

.h8 {
  font-family: "Open Sans";
  font-size: 3rem;
  font-weight: 700;
}

.h9 {
  font-family: "Open Sans";
  font-size: 1.875rem;
  font-weight: 400;
}

.h10 {
  font-family: "Open Sans";
  font-size: 1.25rem;
  font-weight: 700;
}

.h11 {
  font-family: "Open Sans";
  font-size: 1.25rem;
  font-weight: 400;
}

.h12 {
  font-family: "Open Sans";
  font-size: 1rem;
  font-weight: 600;
}

.h13 {
  font-family: "Open Sans";
  font-size: 1rem;
  font-weight: 400;
}

.h14 {
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-weight: 400;
}
// Font colors
.btn-primary {
  height: 48px;
  border-radius: 26px;
  min-width: 148px;
  background-color: #f7941e;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  span {
    color: #ffffff;
  }
}
.mineShaft-txt {
  color: #222222;
}
.white-txt {
  color: #fff;
}
.treePoppy-txt {
  color: #f7941e;
}
.satinLinen-txt {
  color: #e6e5d6;
}
.emperor-txt {
  color: #535353;
}
.left-txt {
  text-align: left;
}
.center-txt {
  text-align: center;
}
.right-txt {
  text-align: right;
}
.grid {
  display: grid;
  align-items: center;
}

.treePoppy-bg {
  background-color: #f7941e;
}

.pacificBlue-bg {
  background-color: #0591c7;
}
.sushi-bg {
  background-color: #72bf44;
}
.mineShaft-bg {
  background-color: #222222;
}
.emperor-bg {
  background-color: #535353;
}
.silver-chalice {
  background-color: #a3a3a3;
}
.gallery-bg {
  background-color: #f0f0f0;
}
.viking-bg {
  background-color: #6ec4d7;
}
.satinLinen-bg {
  background-color: #e6e5d6;
}
.albescentWhite-bg {
  background-color: #f5e6d3;
}
.whiteLinen-bg {
  background-color: #f8f1e8;
}
.white-bg {
  background-color: #ffffff;
}
.relativeElement {
  position: relative;
}
.absoluteElement {
  position: absolute;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-full {
  flex: 1;
}
.login-btn {
  color: #fff;
  background: #f7941e;
  border: none;
  padding: 4% 5% !important;
  width: 12vw;
}
.buffer-bottom {
  padding-bottom: 1.5rem;
  max-height: 90vh;
}
.buffer-bottom1 {
  padding-bottom: 0.875rem;
}
.buffer-bottom2 {
  padding-bottom: 5.375rem;
}
.buffer-bottom3 {
  padding-bottom: 14.45%;
}
.home-section8 {
  display: flex;
  flex-direction: row;
  padding: 7rem 12.5%;
  align-items: center;
}
.home-section8 > .col1 {
  flex: 59.3%;
}
.home-section8 > .col2 {
  flex: 28.8%;
}
.home-section8-btn {
  color: #fff;
  background: #f7941e;
  border: none;
  padding: 2.65% 10%;
}

.pointer {
  cursor: pointer;
}
.notAllowed {
  cursor: not-allowed;
}
.autoCursor {
  cursor: auto;
}
.input-container {
  height: 4rem !important;
  margin-bottom: 1.2rem;
  position: relative;
  .md-error {
    position: absolute;
    right: 12px;
    bottom: 16px;
    font-style: normal;
    font-weight: normal;
    text-align: right;
    letter-spacing: -0.022em;
    color: #f26531;
  }
}
.input-container.md-ripple-error {
  .mdc-text-field__input {
    border-bottom: 3px solid #f26531 !important;
  }
}
.material-input {
  width: 100%;
  height: 100% !important;
  border-radius: 8px 8px 0 0 !important;
  font-family: "Open Sans" !important;
  background: #f0f0f0 !important;
  .mdc-text-field__input {
    border-bottom: 1px solid #a3a3a3 !important;
  }

  .mdc-line-ripple {
    background-color: transparent !important;
  }
  .mdc-floating-label {
    font-family: "Open Sans" !important;
    font-weight: 400;
    // top: 36px !important;
    top: 60% !important;
    font-size: 1.25rem;
    line-height: 24px;
    color: #535353 !important;
  }
  .mdc-floating-label--float-above {
    transform: translateY(-24px) scale(1);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #a3a3a3 !important;
  }
}
