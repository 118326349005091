.about-section {
  padding: 6.875rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10.625rem;
}
.about-section > .col1 {
  text-align: left;
  width: 44.063rem;
}
.about-section > .col2 {
  margin: 3.438rem 0 0;
  width: 53.25rem;
}
figure {
  margin: unset;
}
.aboutIframe {
  width: 100%;
  height: 30rem;
}
.about-section2 {
  padding: 5% 12.6%;
  text-align: left;
}
.about-section2 > .row > Button {
  border: 1px #f7941e solid;
  margin: 1.563rem 1.563rem 0 0;
  box-shadow: unset;
}
figure > img {
  vertical-align: middle;
}
figcaption {
  padding: 2%;
}
@media screen and (max-width: 600px) {
  .about-section {
    gap: unset;
    flex-wrap: wrap;
  }
  .about-section > .col1 {
    width: 100%;
  }
  .about-section > .col2 {
    width: 100%;
  }
}
