.cs-section1 {
  text-align: left;
  padding: 6.75rem 0 0px 5%;
}
.cs-section2 {
  margin: 5.625rem 43% 7.5rem 12.6%;
  text-align: left;
}
.cs-section2-heading {
  text-align: left;
  padding-bottom: 4.75rem;
}
.cs-story {
  padding: 3.25rem 0;
}

.cs-section3 {
  padding: 9.063rem 12.5%;
}
.cs-section3-row {
  display: flex;
  flex-direction: row;
  grid-gap: 10.625rem;
  gap: 10.625rem;
}
.cs-section3-col {
  width: 25.688rem;
}
.cs3-Col {
  width: 25.688rem;
}
.cs-section3-nav {
  padding-top: 1.875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cs-section3-btn {
  align-items: center;
  display: flex;
  padding: unset;
  border: none;
  background: none;
  color: #f7941e;
}
.cs-hr {
  border: 1px solid #535353;
}

@media screen and (max-width: 600px) {
  .cs-section2 {
    // margin: 6rem 12.6% 6rem 12.6%;
    margin: 6rem 5% 6rem 5%;
  }
  .cs-section3 {
    padding: 9.063rem 5%;
  }
  .cs-section3-row {
    flex-wrap: wrap;
    grid-gap: 5rem;
    gap: 5rem;
  }
  .cs-section3-col {
    width: 100%;
  }
  .cs3-Col {
    width: 100%;
    margin-bottom: 10%;
  }
  .cs-section4 {
    flex-wrap: wrap;
    grid-gap: 5rem;
    gap: 5rem;
  }
}
