.loginPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
}

.leftLogin {
  height: 100vh;
}
.admin-login{
  .rightLogin{
    margin-top: 0;
    align-items: center;
    justify-content: center;
  }
  .admin-input{
    width: 40%;
  }
  .btn-login{
    width: 240px;
    margin-top: 42px;
  }
}
