.common-btn {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  // padding: 0.813rem 5.313rem !important;
  min-width: 16.75rem !important;
  min-height: 3.563rem !important;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .common-btn {
    font-size: 15px !important;
    min-height: 40px !important;
  }
}
