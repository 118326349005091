
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}

.addImg {
  position: absolute;
  width: 100px !important;
  bottom: 14px;
  right: 0px;
  transform: translate(30px, 0px);
}
.deleteProj {
  position: absolute;
  top: 0.938rem;
  right: 5.5%;
  padding: unset;
}
.width100 {
  width: 100%;
}

.width90 {
  width: 90%;
}

.dispNone {
  display: none;
}

.treePoppy-txt {
  color: #f7941e;
}

.select-org {
 border: none;
  border-radius: 8px 8px 0px 0px;
  color: #535353;
  background-image: none;
  margin: 0 0 2rem 0;
  outline: none;
}
.select-org > option {
  color: #535353;
}
.email-input {
  color: #a3a3a3 !important;
}

.previewDiv {
  max-width: 170px !important;
  max-height: 170px !important;
  overflow: hidden;
}
.noover {
  position: relative;
}

.newProjectionPopUp {
  box-sizing: border-box;
  border-radius: 24px;
  padding: 2.188rem;
  position: relative;
  margin: 0 auto;
  top: 5%;
  max-width: 30%;
  background-color: white;
  z-index: 99;
}
.icon-btn {
  border: none;
  background: transparent;
}
