.privacy-section {
  margin: 6.875rem 5%;
}

.privacy-section > * {
  width: 53.188rem;
  margin-top: 1.938rem;
}
@media screen and (max-width: 600px) {
  .privacy-section > * {
    width: 100%;
  }
}
