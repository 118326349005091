.guide-section1 {
  padding: 6.875rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.guide-section1 > .col1 {
  text-align: left;
  width: 44.063rem;
}
.guide-section1 > .col2 {
  margin: 3.438rem 0 0;
  width: 53.25rem;
}
.guide-section2 {
  text-align: left;
  padding: 6.875rem 5%;
}
.guide-section2 > .row1 {
  width: 44.063rem;
  padding-bottom: 7.5rem;
}
.guide-section2 > .row1 > Button {
  border: none;
}
.guide-section2 > .row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // gap: 0.938rem;
}
.guide-section2 > .row2 > * {
  margin-right: 1.5rem;
}
.guide-section2 > .row2 > .col {
  width: 53.25rem;
}

.guide-caption {
  padding-right: 17.75%;
}
.guide-cards {
  padding: 1.25rem 9.125rem 1.25rem 2.188rem;
  border-radius: 16px;
  box-sizing: border-box;
  height: max-content;
  //min-height: 250px;
}
.guide-cards > button {
  padding: 10px 0;
  background: transparent;
}

.guide-section3 {
  text-align: left;
  padding: 0 5% 6.875rem;
}
.guide-section3 > .row1 {
  width: 44.063rem;
  padding-bottom: 7.5rem;
}
.guide-section3 > .row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.guide-section3 > .row2 > .col {
  width: 25.875rem;
}
.guide-section3 > .row3 {
  width: 57.75%;
}
.guide-section5 {
  text-align: left;
  padding: 6.875rem 5%;
}
.guide-section5 > .row1 {
  width: 53.188rem;
}
.guide-section6 {
  text-align: left;
  padding: 0 5%;
}
.guide-section6 > .row {
  display: flex;
  flex-direction: row;
}
.guide-section6 > .row1 {
  width: 53.188rem;
  padding-bottom: 4rem;
}

.guide-section6-example {
  width: 73.25rem;
}
.readMore {
  border: none;
  background: transparent;
}
.downArrow {
  cursor: pointer;
}
.downArrow-transform {
  transform: rotate(180deg);
  cursor: pointer;
}
.guide-section4 {
  padding: 6.75rem 5%;
}
.FAQContainer {
  width: 62.375rem;
  text-align: left;
}
.FAQCard {
  padding: 3% 3.5rem;
  margin: 0.625rem 0;
}
.FAQAnswer {
  transition: all 500ms ease-out;
  opacity: 1;
  max-height: 10rem;
}
.hideDiv {
  // transition: all 300ms ease-out;
  // opacity: 0;
  // max-height: 0px;
  display: none;
}
.dataAnswer {
  transition: all 500ms ease-out;
  max-height: 25rem;
  overflow: hidden;
}
.dataQues {
  transition: all 500ms ease-out;
  overflow: hidden;
  max-height: 4.375rem;
}

.checklist-btn {
  color: #fff;
  background: #f7941e;
  border-radius: 50px;
  border: 4px solid #f7941e;
  padding: 2.65% 10%;
}
@media screen and (max-width: 600px) {
  .guide-section1 {
    flex-wrap: wrap;
  }
  .guide-section1 > .col1 {
    width: 100%;
  }
  .guide-section1 > .col2 {
    width: 100%;
  }
  .guide-section2 > .row1 {
    width: 100% !important;
  }
  .guide-section2 > .row2 {
    flex-wrap: wrap;
    // gap: 40px;
    padding-bottom: 40px;
  }
  .guide-section2 > .row2 > * + * {
    margin-top: 5rem;
  }
  .guide-section2 > .row2 > .col {
    width: 100%;
  }
  .guide-section3 > .row2 {
    flex-wrap: wrap;
    gap: 40px;
  }
  .guide-section3 > .row2 > .col {
    width: 100%;
  }
  .guide-section3 > .row3 {
    width: 100%;
  }
  .guide-cards {
    padding: 1.25rem 2.188rem 1.25rem 2.188rem;
  }
  .FAQQues {
    width: 75%;
  }
  .FAQContainer {
    width: 100%;
  }
  .FAQAnswer {
    max-height: 50rem !important;
  }
  .guide-section5 > .row1 {
    width: 100% !important;
  }
  .guide-section6 > .row {
    flex-wrap: wrap;
  }
  .guide-section6 > .row > * + * {
    margin-top: 6rem;
  }

  .guide-section6 > .row1 {
    width: 100% !important;
  }
  .guide-section6-example {
    width: 100% !important;
  }
}
@media screen and (max-width: 1050px) {
  .guide-section6-example {
    width: 100%;
  }
  .FAQAnswer {
    max-height: 25rem;
  }
  .dataAnswer {
    max-height: 50rem;
  }
}
