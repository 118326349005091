// .homeGrid {
//   grid-template-columns: 0.82fr 1fr;
//   grid-template-rows: 1fr 1fr 1fr;
//   grid-column-gap: 10rem;
//   grid-row-gap: 14.5rem;
// }
.page-container {
  padding: 6rem 5% 6rem 12.6%;
}
.spaceBetween {
  justify-content: space-between;
}
.home-section1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.home-section1-txt {
  padding: 5% 0;
}
.home-section1 > .col1 {
  width: 39.25%;
}
.home-section1 > .col2 {
  width: 44.75%;
}
.home-section1-txt > Button {
  color: #fff;
  background: #f7941e;
  border: none;
  padding: 1.85% 6.75%;
  margin-top: 13.55%;
}

.home-section2 {
  padding: 5.25% 0;
}

.home-section3 {
  display: flex;
  flex-direction: row;
  padding: 9.188rem 12.6%;
  align-items: center;
}
.home-section3 > .col1 {
  padding-right: 12.85%;
  flex: 32.75%;
}
.home-section3 > .col2 {
  flex: 67.25%;
}
.home-section3-col2 {
  padding: 0 16.65%;
}

.home-section4 {
  padding: 7.938rem 12.6% 0% 12.6%;
}
.home-section4-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 14.375rem;
}
// .home-section4 > .col1 {
//   // padding-right: 2%;

// }
.home-section4-row > .col1 {
  width: 38.75%;
}
.home-section4-row > .col2 {
  width: 49.05%;
}
.home-section4-btn {
  border: 1px solid #f7941e;
  background: none;
  padding: 2.5% 7%;
  color: #f7941e;
}
.home-section4-btn2 {
  border: 1px solid #f7941e;
  background: #f7941e;
  padding: 2.5% 7%;
  color: #fff;
}
.home-section5 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-section5 > .col1 {
  flex: 42%;
  height: 100%;
}
.home-section5 > .col2 {
  flex: 58%;
  height: 100%;
}
.home-section5-col1 {
  padding: 3.125rem 28%;
}
.home-section5-btn {
  border: 1px solid #fff;
  background: #f7941e;
  padding: 3.75% 24.75%;
  color: #fff;
}
.home-section6 {
  padding: 9.063rem 12.5%;
}
.home-section6-col {
  // padding: 0 2.5%;
  width: 35.063rem;
}
.img-btn {
  border: none;
  background: none;
  cursor: pointer;
}
.home-section6 > .header {
  padding-bottom: 6.25rem;
}
.home-section6-row {
  display: flex;
  flex-direction: row;
  gap: 10.625rem;
}
.caseStudiesCol {
  width: 35.063rem;
}
.home-section6-btn {
  color: #f7941e;
  border: 1px solid #f7941e;
  background: none;
  padding: 1% 5.5%;
  margin-top: 6.75%;
}
.home-section7 {
  display: flex;
  flex-direction: row;
  padding: 10rem 12.5%;
  align-items: center;
}
.home-section7 > .col1 {
  flex: 28.8%;
}
.home-section7 > .col2 {
  padding-left: 12%;
  flex: 59.3%;
}
.home-section7-img {
  height: max-content;
  object-fit: cover;
}
.home-section3-slide {
  z-index: 90;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  .img-row {
    width: 100%;
    height: 220px;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    .empty {
      min-width: 22%;
      height: 122px;
      width: auto;
      transition: all 1s ease;
    }
    .empty.no {
      min-width: 0;
      width: 0;
      height: 0;
    }
  }
  .slide-img {
    position: absolute;
    object-fit: contain;
    width: 0;
    height: 0;
    left: -100%;
    opacity: 0;
  }
  .slide-img.ani {
    transition: all 1s ease;
  }
  .slide-img.ani-2 {
    transition: left, right 1s ease !important;
  }
  .slide-img.next-prev {
    width: 22% !important;
    height: 122px;
    left: 100%;
    opacity: 0;
  }
  .slide-img.prev-next {
    width: 22% !important;
    height: 122px;
    left: -23%;
    opacity: 0;
  }
  .slide-img.next {
    width: 22% !important;
    height: 122px;
    left: 78%;
    opacity: 1;
  }
  .slide-img.prev {
    width: 22% !important;
    height: 122px;
    left: 0;
    opacity: 1;
  }
  .slide-img.active {
    width: 32% !important;
    height: 192px;
    margin: 0 12%;
    margin-bottom: 24px;
    right: 0;
    left: 22%;
    position: absolute;
    opacity: 1;
  }
  .txt-row {
    margin-top: 80px;
    height: 200px;
    align-items: flex-start;
    .btn-view {
      width: 16% !important;
      align-items: flex-start;
      justify-content: center;
      button {
        background-color: transparent;
        outline: none;
        border: none;
        width: 40px;
        height: 40px;
        // margin: 32px 32px 0 0;
      }
      img {
        width: 1.1vw !important;
        height: auto;
        object-fit: contain;
      }
    }
    .carousel-wrap {
      width: 68%;
      height: 100%;
      overflow: hidden;
    }
    .text-area {
      transform-style: preserve-3d;
      height: 100%;
      .text-content {
        min-width: 100%;
        height: 100%;
        z-index: 100;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s, z-index 0.5s;
      }
      .text-content.active {
        opacity: 1;
        position: relative;
        z-index: 900;
      }
    }
  }
}

.video-frame {
  width: 52rem;
  height: 30rem;
}
@media screen and (max-width: 600px) {
  .page-container {
    // padding: 6rem 12.6% 6rem 12.6%;
    padding: 6rem 5% 6rem 5%;
  }
  .home-section1 {
    flex-wrap: wrap;
    gap: 5rem;
  }
  .home-section1 > .col1 {
    width: 100%;
  }
  .home-section1 > .col2 {
    width: 100%;
  }
  .home-section2 {
    padding: 3.35%;
  }
  .home-section3 {
    flex-wrap: wrap;
    gap: 5rem;
    padding: 9.188rem 0;
  }
  .home-section3 > .col1,
  .home-section3 > .col2 .home-section3-slide {
    padding: 0 12.6%;
    .img-row {
      height: 170px;
    }
    .txt-row {
      margin-top: 0;
      height: 250px;
      .left {
        justify-content: center;
      }
      .right {
        justify-content: center;
      }
      .btn-view {
        width: 16% !important;
        button {
          width: 20px;
          height: 28px;
          margin: 0 0;
        }
        img {
          width: 12px !important;
          height: 28px;
        }
      }
      .carousel-wrap {
        width: 68%;
      }
    }
  }
  .home-section3 > .col2 {
    padding: 0;
  }
  .home-section3 > .col2 {
    padding: 0;
  }
  .home-section3-col2 {
    padding: unset;
  }
  .home-section4-row {
    flex-wrap: wrap-reverse;
    gap: 5rem;
  }
  .home-section4-row > .col1 {
    width: 100%;
  }
  .home-section4-row > .col2 {
    width: 100%;
  }
  .home-section5 {
    flex-wrap: wrap;
    gap: 5rem;
  }
  .home-section5 > .col1 {
    flex: 100%;
  }
  .home-section5 > .col2 {
    flex: 100%;
  }
  .home-section5-col1 {
    padding: 5rem 12.5%;
  }
  .home-section6-row {
    flex-wrap: wrap;
    gap: 5rem;
  }
  .home-section7 {
    flex-wrap: wrap;
    // gap: 5rem;
  }
  .home-section7 > * + * {
    margin-top: 5rem;
  }
  .home-section7 > .col1 {
    flex: 100%;
  }
  .home-section7 > .col2 {
    flex: 100%;
    padding: unset;
  }
  .home-section8 {
    flex-wrap: wrap;
    //gap: 8rem;
  }
  .home-section8 > * + * {
    margin-top: 8rem;
  }
  .home-section8 > .col1 {
    flex: 100%;
  }
  .home-section8 > .col2 {
    flex: 100%;
  }
  .caseStudiesCol {
    width: 100%;
    margin-bottom: 10%;
  }
  .programs-section2-row > .col1 {
    padding-bottom: 7rem !important;
  }
  .video-frame {
    width: 100% !important;
  }
}
