.programs-section1 {
  padding: 6.75rem 58.25% 6.75rem 5%;
  text-align: left;
}
.programs-section2 {
}
.programs-section2-row {
  padding: 0 12.6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
  gap: 19.75rem;
  padding-top: 5rem;
}
.programs-box:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.programs-box:nth-of-type(even) {
  background-color: #fff;
}

.programs-box {
  display: inline-block;
  width: 100vw;
}
.programs-section2-row > .col1 {
  width: 25.813rem;
  padding-bottom: 9.125rem;
}
.programs-section2-row > .col2 {
  width: 44.063rem;
  padding-bottom: 5rem;
}
.programs-section3 {
  padding: 6.75rem 35.15% 6.75rem 5%;
  text-align: left;
}
.table-view {
  // margin: 0 0 160px 96px;
  // width: 1142px;
  width: 71.375rem;
}
.table-wrapper {
  margin: 0 0 10rem 5%;
  // width: 50%;
}
.table-grid {
  display: grid;
  grid-template-columns: 0.24fr 0.25fr 0.17fr 0.17fr 0.17fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border: 2px solid #f5e6d3;
  border-radius: 8px 8px 8px 8px;
}
.table-grid-header {
  padding-top: 2.125rem;
  background: #f5e6d3;
  min-width: 16rem;
}
.table-grid-data {
  text-align: left;
  background: #f8f1e8;
  padding: 9.5% 0 0 9.5%;
  border: 2px solid #f5e6d3;
}
.table-sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}
.table-item {
  grid-column: 3/-1;
  padding: 9.5% 0 9.5% 9.5%;
  text-align: left;
  border-radius: 0px 0px 8px 0px;
}
@media screen and (max-width: 600px) {
  .programs-section1 {
    padding: 6.75rem 5% 6.75rem 5%;
    text-align: left;
  }
  .programs-section2-row {
    gap: unset !important;
    flex-wrap: wrap;
  }
  .programs-section3 {
    padding: 6.75rem 5% 6.75rem 5%;
  }
  .table-wrapper {
    //width: 100vw;
    overflow: scroll;
  }
  .table-grid-header {
    min-width: 18.75rem;
  }
}
