body {
  height: 100vh;
}

@media screen and (max-width: 1050px) {
  .borderSelect {
    padding-right: 0px !important;
    width: 100% !important;
  }
}


.noover {
  overflow: hidden !important;
  height: 100vh;
}

/* Radio buttons */

input[type="radio"],
input[type="checkbox"] {
  height: auto !important;
  width: 20px;
}

// input[type="radio"]:after {
//   width: 16px !important;
//   height: 16px !important;
//   border-radius: 15px;
//   top: 4px;
//   left: 0px;
//   position: relative;
//   background-color: #d1d3d1 !important;
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }

// input[type="radio"]:checked:after {
//   width: 16px !important;
//   height: 16px !important;
//   border-radius: 15px;
//   top: 4px;
//   left: 0px;
//   position: relative;
//   background-color: #f7941e !important;
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }

.treePoppy-border {
  border: 3px solid #f7941e;
}

.whisper-bg {
  background-color: #e5e5e5;
}

.blackWhite-bg {
  background-color: #e6e5d6;
}

.pink-bg {
  background-color: #a3a3a3;
}

.upperbound {
  background-color: #535353;
}

.lowerbound {
  background-color: #a3a3a3;
}

.matterhorn-bg {
  background-color: #535353;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 16px;
  display: inline-block;
}



.opacity70 { 
  opacity: 0.7;
}

.silver-chalice-txt {
  color: #a3a3a3;
}

.whisper-txt {
  color: #e5e5e5;
}

.matterhorn-txt {
  color: #535353;
}

.pacificBlue-txt {
  color: #0591c7;
}

.flex {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentFlexEnd {
  justify-content: flex-end !important;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.alignItemsCenter {
  align-items: center;
}

.widthChart {
  width: 300px;
  margin-right: 16px;
  min-width: 300px;
}

.width96 {
  width: 96% !important;
}

.width41 {
  width: 41%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25% !important;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.width50 {
  width: 50% !important;
}

.width55 {
  width: 55% !important;
}

.width60 {
  width: 60%;
}

.width65 {
  width: 65%;
}

.width75 {
  width: 75%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}
.width85 {
  width: 85%;
}
.width90 {
  width: 90%;
}

.width90Imp {
  width: 90% !important;
}

.width100 {
  width: 100% !important;
}

.width90 {
  width: 90%;
}

.width40 {
  width: 40%;
}

.width36 {
  width: 36%;
}

.width32 {
  width: 32%;
}
.container {
  input,
  select {
    border: none;
    height: 64px;
    margin-bottom: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: flex-end;
    padding: 0px 0px 0px 24px;
    line-height: 64px;
    outline: none;
  }
}
select {
  padding: 20px 16px 6px;
  min-height: 48px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
select::-ms-expand {
  display: none;
}

.companyCard {
  input,
  select {
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: flex-end;
    padding: 16px !important;
    margin-bottom: 0px !important;
    outline: none;
    height: fit-content !important;
    line-height: normal !important;
  }
}
.companyCard select {
  min-height: 48px !important;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  position: relative;
}

.floatingLabel{
  color: #a3a3a3;
  font-size: 14px;
  text-align: left;
  margin-left: 16px;
  margin-bottom:-20px;
  z-index:100000;
  position: relative;
}

.hideProvider{
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}
.currencyBorder {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 16px;
}
.md-check, .md-radio {
  display: none !important;
}
.md-radio-container{
  display: flex;
  align-items: center;
}
.md-check:checked + .md-check-label,  .md-radio:checked + .md-radio-label{
  span {
    visibility: visible;
    opacity: 1;
  }
}
.md-check + .md-check-label, .md-radio + .md-radio-label {
  border: 2px solid #f7941e;
  box-sizing: border-box;
  border-radius: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  overflow: hidden;
  display: inline-flex;
  span {
    opacity: 0;
    background-color: #f7941e;
    width: 12px;
    height: 12px;
    display: inline-flex;
    transition: opacity 0.3s ease;
    -webkit-backface-visibility: hidden;
  }
}
.md-radio + .md-radio-label{
  border-radius: 50%;
  margin-right: 8px;
  span {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
.gbl-select {
  position: relative;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .caret {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    width: 4% !important;
    height: 12px;
    object-fit: contain;
    cursor: pointer;
  }
  select {
    width: 100%;
    height: 100%;
    font: inherit;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
.borderSelect {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-right: 16px;
  border-bottom: 1px solid #a3a3a3;
}

.borderNone {
  border: 0px;
}

.borderLeft {
  border-left: 1px solid #a3a3a3;
}

.borderBottom {
  border-bottom: 1px solid #a3a3a3;
}

.border1px {
  border: 1px solid #a3a3a3;
}

.outlineNone {
  outline: none;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.dispNone {
  display: none;
}

.minHeight90 {
  min-height: 90vh;
}

.height32 {
  height: 32px;
  width: auto !important;
}

.height40px {
  height: 40px;
  width: auto !important;
}

.height48 {
  height: 48px;
  width: auto;
  animation: rotation 4s infinite linear;
}

.height40Per {
  height: 40%;
}

.height20Per {
  height: 20%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.height100 {
  height: 100vh;
}

.height70 {
  height: 70vh;
}

.height40 {
  height: 40vh;
}

.height50 {
  height: 50vh;
}

.height50Percent {
  height: 50%;
}

.bar {
  padding: 2% 5% 0% 5%;
}

.link-container {
  white-space: nowrap;
}

.lineHeight24{
  line-height: 24px;
}

.padBot3rem {
  padding-bottom: 3rem;
}

.padBot16 {
  padding-bottom: 16px;
}

.padBot8 {
  padding-bottom: 8px;
}

.padTop16 {
  padding-top: 16px;
}

.padTop24 {
  padding-top: 24px;
}

.padTop48 {
  padding-top: 48px;
}

.pad8 {
  padding: 0px 8px;
}

.padRight24 {
  padding-right: 24px !important;
}

.padRight48 {
  padding-right: 48px;
}

.pad24 {
  padding: 24px;
}

.pad4 {
  padding: 6px 16px;
}

.pad0 {
  padding: 0px !important;
}

.padLeft24 {
  padding-left: 24px;
}

.padLeft48 {
  padding-left: 48px;
}

.padLeft32 {
  padding-left: 32px;
}

.padLeft16 {
  padding-left: 16px;
}

.bundleDesc ul {
  margin-top: 0px !important;
}

.notApplicableMargin {
  margin-top: 90px;
  margin-bottom: 100px;
}

.info{
  height:20px;
  width:auto;
  margin-left:4px;
}

.margBotAdd{
  margin-bottom: 80px;
}

.margLeftMinus24 {
  margin-left: -24px;
}

.margBot32 {
  margin-bottom: 32px;
}

.margBot0 {
  margin-bottom: 0px;
}

.margTop8 {
  margin-top: 8px;
}

.margLeft125 {
  margin-left: 12.5%;
}

.margLeft25 {
  margin-left: 25%;
}

.margLeft16 {
  margin-left: 16px;
}

.margLeft24 {
  margin-left: 24px;
}

.margTop16 {
  margin-top: 16px;
}

.margTop32 {
  margin-top: 32px;
}

.margBot8 {
  margin-bottom: 8px;
}

.margBot16 {
  margin-bottom: 16px;
}

.margRight8 {
  margin-right: 8px;
}

.margRight16 {
  margin-right: 16px;
}

.margRight24 {
  margin-right: 24px;
}

.margBot4 {
  margin-bottom: -4px;
}

.card {
  padding: 2% 5.5%;
  width: 95%;
  min-height: 5.625rem;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-bottom: 1.5rem;
}

.popupCard {
  z-index: 95;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-color: rgba(22, 22, 22, 0.5); /* complimenting your modal colors */
}


.forgotPassword {
  max-width: 35rem !important;
}
.popup {
  min-width: 36.375rem;
  border-radius: 24px;
  width: fit-content;
  padding: 2.375em;
  position: relative;
  margin: 0 auto;
  top: 5%;
  max-width: 90%;
  background-color: white;
  z-index: 99;
}

.popupConfirm {
  min-width: 36.375rem;
  border-radius: 24px;
  width: fit-content;
  padding: 2.375em;
  position: relative;
  margin: 0 auto;
  top: 25%;
  max-width: 40%;
  background-color: white;
  z-index: 99;
}

.popupCardTop35{
  top:35% !important;
  width:50% !important
}

.popupSmall {
  min-width: 36.375rem;
  border-radius: 24px;
  width: 30%;
  padding: 2.375em;
  position: relative;
  margin: 0 auto;
  top: 20%;
  max-width: 90%;
  background-color: white;
  z-index: 99;
}

.popupChart {
  top: 5% !important;
  overflow: auto;
  max-height: 83vh;
}

.chartCard {
  border-radius: 16px;
  padding: 16px;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.popupHigher {
  border-radius: 24px;
  width: 35rem;
  padding: 2.5rem;
  position: relative;
  margin: 0 auto;
  top: 5%;
  background-color: white;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.programCard {
  border-radius: 27px;
  margin-bottom: 24px;
  margin-left: 24px;
  background-color: white;
  width: 27%;
  cursor: pointer;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  -ms-transition: all 0.25s;
  min-height: 420px;
}

.programCard:hover {
  transform: scale(
    1.05
  );
}

@media (hover: none) {
  .programCard:hover {
    transform: scale(
      1
    );
  }

  .programHeader{
    min-height: 0px !important;
  }

  .programCard{
    max-height: none !important;
  }

  .newProjectionPopUp{
    top:10% !important;
    max-width: 50% !important
  }

  .popupHigher{
    max-width: 50% !important;
    width: 50% !important;
  }
}

.programCard img {
  padding: 24px;
}

.programHeader {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  min-height: 170px;
  position: relative;
}

.check{
  width:28px !important;
  height:28px;
  padding: 0px !important;
  position: absolute;
  left:16px;
  top:16px;
}

.targetClass {
  min-height: 80px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.companyCard {
  border-radius: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  background-color: white;
  width: 90%;
  padding: 5%;
}

.roiCard {
  border-radius: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
  background-color: white;
  padding: 3%;
  text-align: left;
}

.impactCard {
  border-radius: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
  padding: 4% 2%;
  text-align: left;
}

.impactCard div .h7 {
  text-align: center;
  white-space: nowrap;
}

.rightSection {
  padding-right: 5%;
  padding-top: 48px;
  width: 80%;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 72vh;
}

.rightSectionRoi {
  height: 70vh !important;
}

.flexWrap {
  flex-wrap: wrap;
}

.scrollYAuto {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollY {
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollX {
  overflow-x: auto;
}

.nowrap {
  white-space: nowrap;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 4px;
}

.grayBorderLeft {
  border-left: 1px solid #a3a3a3;
  min-height: 72px;
}

.flexReverse {
  display: flex;
  flex-direction: column-reverse;
  height: max-content;
}

.bundle ul {
  padding: 0px 16px;
}

.relativePos {
  position: relative;
}

.deletbtn {
  width: 24px !important;
  height: 24px;
  margin-left: 24px;
  margin-right: 8px;
}

.tooltip {
  position: relative;
  height:20px;
}

.tooltip .tooltiptext {
  visibility: hidden;  
  min-width: 120px;
  background-color: #EEEEEE;
  color: #535353;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-radius: 12px;
  padding: 2px 0;
  margin-top:-16px;
  margin-left:8px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptextchart {
  visibility: hidden;  
  min-width: 120px;
  background-color: #ffffff;
  color: #535353;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-radius: 12px;
  padding: 2px 0;
  margin-top:-16px;
  margin-left:8px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.right0 {
  right:0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextchart {
  visibility: visible;
}